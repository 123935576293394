<template>
    <div v-if="$store.state.isAdvanceModalOpen" class="modal fade show" aria-labelledby="addNewCardTitle" style="display: block; background: #1c1d1c33">
        <div class="modal-dialog modal-dialog-centered" ref="modalRef">
            <div class="modal-content">
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex justify-content-between px-1 my-1 align-item-center">
                            <h1 class="mb-0">{{ 'Select Advance Receipt' }}</h1>
                            <button
                                @click="onClickClose"
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                    </div>
                </div>

                <hr class="mt-0 mb-0">

                <div class="pb-2">

                    <div class="col-11 py-1 mx-auto">
                        <table class="table border">
                            <thead>
                                <tr>
                                   <th>
                                       Receipt Number
                                   </th>
                                    <th>Available Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(receipt, i) in receipts" :key="i">
                                    <td
                                        @click="setReceiptId(receipt.id, receipt.available_amount)"
                                        class="cursor-pointer"
                                    >
                                        <input
                                            type="checkbox"
                                            name="selected-receipt"
                                            id="selected-receipt"
                                            :checked="receiptId === receipt.id"
                                        />
                                        {{receipt.voucher_no}}
                                    </td>
                                    <td>
                                        {{receipt.available_amount}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="mt-2">
                            <p> <span class="text-bold">Bill Amount:</span> {{total}} </p>
                            <div class="form-group row">
                                <label
                                    class="col-sm-4 col-form-label text-body text-bold"
                                    for="advance-amount"
                                    style="font-size: 1rem"
                                >
                                    Advance Amount:
                                </label>
                                <input
                                    name="amount"
                                    type="number"
                                    v-model="advanceAmount"
                                    class="form-control width-50-per"
                                    id="advance-amount"
                                />
                                <transition name="fade">
                                    <span
                                        v-if="formError.advanceAmount"
                                        class="invalid-feedback d-block qcont"
                                    >
                                        {{ formError.advanceAmount }}
                                    </span>
                                </transition>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 text-center">
                        <SubmitCancelBtn @on-submit="submit" @on-cancel="onClickClose"  :loading="loading"/>
                    </div>

                </div>
            </div>
        </div>
        <Loader v-if="loader"/>
    </div>
</template>

<script setup>
import Loader from "@/components/atom/LoaderComponent";
import {inject, onMounted, ref, watch} from "vue";
import { useStore } from "vuex";
import { useRoute } from 'vue-router';
import SubmitCancelBtn from "@/components/atom/SubmitCancelBtn.vue";

const props = defineProps({
    receipts: {
        type: Array,
        default: []
    },
    total: {
        type: Number
    }
});
const emit = defineEmits(['onCreateProfile']);
const $store = useStore();
const $route = useRoute();
const modalRef = ref(null);
const formData = inject("formData");
const receiptId = ref(null);
const advanceAmount = ref(0);
const availableAmount = ref(0)
const loading = ref(false);
const loader = ref(false);
const formError = ref({
    advanceAmount: ""
});
const showError = inject('showError');
const showSuccess = inject('showSuccess');

//Functions and methods
const submit = () => {
    formData.value.advance_receipt_id = receiptId.value;
    formData.value.advance_amount = advanceAmount.value;
    onClickClose();
}

const setReceiptId = (id, amount) => {
    receiptId.value = id ;
    availableAmount.value = amount;
    advanceAmount.value = amount > props.total ? props.total : amount;
}

const onClickClose = () => {
    $store.state.isAdvanceModalOpen = false;
}

//watchers
watch(advanceAmount, (newValue) => {
    formError.value.advanceAmount = '';

    if (!newValue) {
        formError.value.advanceAmount = "Advance amount cannot be 0";
    }

    if (newValue > props.total) {
        formError.value.advanceAmount = `Advance amount cannot be bigger than total amount (${props.total})`;
    }

    if (newValue > availableAmount.value) {
        formError.value.advanceAmount = `Advance amount cannot be bigger than available amount (${availableAmount.value})`;
    }
})

onMounted(() => {
    receiptId.value = formData.value.advance_receipt_id;
    advanceAmount.value = formData.value.advance_amount > props.total ? props.total : formData.value.advance_amount;
    const receipt = props.receipts.find(receipt => receipt.id === formData.value.advance_receipt_id);
    if (receipt) {
        availableAmount.value = receipt.available_amount
    }
})

</script>
